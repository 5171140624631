button,
.button {
    display: table;
    padding: $btn-padding;
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    letter-spacing: 1.2px;
    line-height: inherit;
    text-decoration: none;
    border: 1px solid $primary;
    font-size: 1rem;
    color: inherit;

    &:hover {
        transition: 0.2s ease-in-out;
    }

    &:focus {
        outline: 0;
    }

    &.primary {
        background: $primary;
        color: $white;

        &:hover {
        }
    }

    &.underline{
        text-decoration: underline;
        margin-bottom: 0.5em;
        border: none;
        margin-bottom: 0.5em;
        color: inherit;
        font-size: 0.85rem;
        line-height: 20px;
    }

    &.secondary {
        border: none;
        background: $secondary;
        color: $white;
        margin-right: 0.5em;

        &:hover {
        }
    }

    &.border {
        background: transparent;
        box-shadow: inset 0 0 0 1px $white;
        border: none;
        color: $white;

        &:hover {
        }
    }
}
