table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    thead {
        background-color: #d9d9d9;
        color: $black;
    }

    th {
        font-size: 1rem;
        padding: 0.625em;
        white-space: break-spaces;
        line-height: 20px;
    }

    tr {
        border-bottom: 1px solid #ddd;
        padding: 0.35em;
    }

    td {
        padding: 1em 1.5em;
        text-align: left;
        font-size: 0.85rem;
        line-height: 20px;
        vertical-align: top;

        &.download-links {
            ul li {
                list-style-type: none;
                margin-bottom: 1em;
            }

            .file-download {
                position: relative;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    padding-right: 2em;
                    vertical-align: middle;
                    background-image: url("/img/file-icon.svg");
                    background-size: 20px 20px;
                    background-repeat: no-repeat;
                }
            }
        }

        &.products {
            ul {
                padding-left: 1em;
                li {
                    color: $primary;
                    font-weight: 500;
                    margin-bottom: 0.25em;
                }
            }
        }
    }

    .brand-img {
        display: block;
        width: 200px;
        height: 90px;
        object-fit: cover;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    table td {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.8em;
        text-align: left;
        padding: 1em 0;
        border-bottom: 1px solid #ddd;
    }

    table td::before {
        /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }

    table td:last-child {
        border-bottom: 0;
    }
}