// Colours
$white: #ffffff;
$black: #000000;
$light-grey: #dfdfdf;
$dark-grey: #4a4a4a;

$primary: #e70800;
$secondary: #49474a;

$positive: #0ab04b;
$negative: #e64747;

$label: #1c4c56;
$input-bg: #ffffff;
$input-border: #dfdfdf;
$input-font: #212121;

// Spacing
$btn-padding: 0.85rem 1.5rem;

// Typography
$font: "Inter", sans-serif;
