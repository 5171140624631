@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_table.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";

@import "partials/_cart.scss";

//Font
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    position: relative;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    p {
        font-size: 1.125rem;
        line-height: 25px;

        @include xs {
            font-size: 1rem;
            line-height: 20px;
        }
    }

    a {
        color: inherit;
    }

    label {
        padding: 0.5rem 0.5rem 0.5rem 0;
        font-size: 0.85rem;
    }
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 0 40px;
    width: 100%;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include xs {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include xs {
        flex-direction: column;
    }

    &.start {
        align-items: flex-start;
    }

    &.half-col {
        fieldset {
            width: 100%;
            max-width: 50%;

            @include xs {
                max-width: 100%;
            }
        }
    }
}

// -- Global

.g-underline {
    color: inherit;
    text-decoration: underline;
}

.g-text-white-line {
    width: 40%;
    margin: 1.5em 0;
    border-top: 2px solid $white;
}

.g-2col-grid-flex {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include md {
        display: flex;
        flex-direction: column-reverse;
    }
}

.g-buttons-row {
    display: flex;
    margin-top: 1.5em;

    .button:first-child {
        margin-right: 2em;
    }
}

nav {
    &.breadcrumbs {
        text-align: left;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}
body > header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }
        // &.user {
        //     .cart {
        //         box-shadow: 5px 5px 5px rgba($off-black, 0.2);
        //         .cart-items {
        //             padding-top: 10px!important;
        //             padding-bottom: 0!important;
        //             .cart-item {
        //                 padding: 0 0;
        //                 .item-details {
        //                     flex: 1 1 auto;
        //                 }
        //                 .item-qty {
        //                     flex: 0 0 auto;
        //                 }
        //                 .item-price {
        //                     flex: 1 1 auto;
        //                     text-align: right;
        //                 }
        //                 .item-remove {
        //                     flex: 0 0 auto;
        //                     margin-right: -10px;
        //                     margin-left: 10px;
        //                 }
        //             }
        //         }
        //         .totals {
        //             font-weight: 600;
        //             padding:  5px 10px 5px 5px;
        //             border-top: 1px dotted $off-black;
        //         }
        //     }
        // }
    }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// -- Delta Med

// -- Header
header {
    .top-header {
        background-color: $primary;

        .container {
            padding: 8px 40px;

            @include xs {
                padding: 8px 20px;
            }
        }

        .row {
            @include md {
                flex-direction: column;
            }

            .column {
                @include md {
                    margin: 0.5em 0;
                }
            }
        }

        ul {
            display: flex;
            align-items: center;

            li {
                list-style-type: none;
                color: $white;
                line-height: 2px;

                &.social-media {
                    display: flex;
                    list-style-type: none;

                    a {
                        margin-right: 0.5em;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        #login-form-top {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            small {
                margin: 0 0.75rem 0 0;
                white-space: nowrap;
                color: $white;
            }

            fieldset {
                display: flex;
                align-items: center;

                &.buttons {
                    button {
                        width: auto;
                        padding: 0;
                        margin: 0;
                        border-radius: 0;
                        padding: 6px 16px;
                        color: $white;
                        font-size: 80%;
                        background-color: $dark-grey;
                    }
                }

                + fieldset {
                    margin-left: 0.5rem;
                }
            }

            input {
                margin: 0;
                padding: 0.375rem 0.625rem;
                border: none;
                border-radius: 0;
                font-size: 80%;
            }
        }

        .account-setting,
        .logout {
            color: $white;
            text-decoration: none;
            font-size: 1rem;
            margin: 0 1rem;

            img {
                display: inline-block;
                height: 16px;
                margin-right: 0.5em;
            }
        }
    }

    .middle-header {
        width: 100%;
        background-color: $white;

        .row {
            @extend .container;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 40px;

            @include md {
                flex-direction: row;
            }

            @include xs {
                padding: 1rem 20px;
            }
        }

        .logo {
            display: block;
            width: auto;
            height: 55px;

            @include lg {
                height: 40px;
            }

            @include xs {
                height: 36px;
            }
        }

        .header-nav {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include md {
                background-color: $white;
            }

            a {
                text-decoration: none;
            }

            ul {
                background-color: transparent;
                list-style: none;
                margin: 0;
                padding-left: 0;
                display: flex;

                @include md {
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 1.5em 1em;
                }

                li {
                    color: $dark-grey;
                    background: transparent;
                    display: block;
                    padding: 1em;
                    text-decoration: none;
                    transition-duration: 300ms;
                    margin: 0;

                    &:hover {
                        a {
                            color: $primary;
                        }
                    }

                    &.li-overlay {
                        margin-right: 1em;

                        &.small-pos {
                            position: relative;

                            &:hover {
                                a {
                                    color: $white;
                                }
                            }
                        }

                        &:hover {
                            background: $primary;
                            z-index: 4;

                            .categories-menu {
                                color: $white;

                                &:after {
                                    content: "";
                                    display: inline-block;
                                    margin-left: 0.5rem;
                                    width: 24px;
                                    height: 16px;
                                    vertical-align: middle;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    background-position: center;
                                    background-image: url("/img/up-arrow.svg");
                                }
                            }
                        }
                    }

                    @include md {
                        padding: 0 0 1em;
                        text-align: left;

                        &:before {
                            content: "";
                            display: inline-block;
                            width: 10px;
                            height: 16px;
                            margin-right: 0.75em;
                            vertical-align: middle;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                            background-image: url("/img/arrow-right.svg");
                        }
                    }

                    a {
                        color: $secondary;
                        font-size: 1.125rem;
                        font-weight: 500;
                    }

                    > ul {
                        background: white;
                        visibility: hidden;
                        opacity: 0;
                        min-width: 250px;
                        position: absolute;
                        transition: all 0.5s ease;
                        margin-top: 0.15rem;
                        left: 1em;
                        top: 3em;
                        padding: 1em 1em;
                        display: none;
                        z-index: 200;
                        z-index: 100;
                        transition: all 300ms ease;

                        @include md {
                            background: transparent;
                            opacity: 1;
                            visibility: visible;
                            position: static;
                            display: block;
                            margin: 0;
                            min-width: 100%;
                            padding: 1em 0 0 1.5em;
                        }

                        &.dropdown.small {
                            width: fit-content;
                            height: fit-content;
                            padding: 1em 1em 0 1em;

                            li {
                                padding: 0 0 1em 0;
                            }
                        }

                        a {
                            color: $black !important;
                            font-weight: 400;
                            display: block;
                            text-align: left;
                            width: 100%;

                            &:hover {
                                color: $primary !important;
                            }
                        }

                        > li {
                            padding: 0 1em 1em 0;
                            position: relative;
                            margin: 0;
                            width: fit-content;

                            @include md {
                                display: inline-flex;
                                padding: 0 1em 1em 0;
                            }

                            &:hover {
                                background-color: transparent !important;
                            }

                            > ul {
                                background: white;
                                visibility: hidden;
                                opacity: 0;
                                min-width: 5rem;
                                position: absolute;
                                left: 100%;
                                top: -0.15rem;
                                display: none;

                                &:hover {
                                    visibility: visible;
                                    opacity: 1;
                                    display: flex;
                                    flex-direction: column;
                                    flex-wrap: wrap;
                                    align-content: flex-start;
                                }
                            }
                        }

                        &:hover {
                            visibility: visible;
                            opacity: 1;
                            display: flex;
                        }
                    }
                }
            }

            ul li:hover > ul,
            ul li ul:hover {
                visibility: visible;
                opacity: 1;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
            }
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: block;

    @include md {
        display: none;
    }
}

.burger-menu {
    display: none;

    @include md {
        width: 30px;
        height: 30px;
        display: block;
        margin-left: 0.5em;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: black;
            border: none;
            height: 3px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    position: fixed;
    background: $dark-grey;
    top: 170px;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    // overflow: hidden;
    z-index: 100;

    @include xs {
        top: 155px;
    }

    &.open {
        opacity: 0.98;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }
    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    display: block;
}

// -- Footer

.top-footer {
    background-color: $secondary;
    padding: 1.5em 0;

    .container {
        padding: 20px 40px;

        @include xs {
            padding: 20px;
        }
    }

    .row {
        @include md {
            flex-direction: column;
            align-items: center;
            padding: 0;
        }
    }

    .column.left {
        display: flex;
        align-items: center;

        .logo {
            display: block;
            width: auto;
            height: 55px;

            @include md {
                height: 40px;
            }

            @include xs {
                height: 36px;
            }
        }
    }

    .column.right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 4em;

        @include md {
            margin-left: 0;
            justify-content: center;
            margin-top: 1.5em;
        }

        .footer-menu {
            text-align: left;

            @include md {
                min-width: 33.33%;
            }

            @include xs {
                min-width: 50%;
            }

            .footer-menu-bottom {
                margin-top: 1em;
            }

            .footer-menu-header {
                text-transform: uppercase;
                color: $primary;
                font-weight: 600;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                li {
                    list-style-type: none;
                    margin: 0 1rem;

                    a {
                        color: $white;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.bottom-footer {
    background-color: $primary;
    color: $white;
    padding: 1em 0;
    display: flex;
    justify-content: center;

    @include md {
        text-align: center;
        flex-direction: column;
    }

    .credit-link {
        font-size: 0.75rem;
        font-weight: 400;
        color: $white;
        text-decoration: none;

        &.right {
            margin-left: 2em;

            @include xs {
                margin-left: 1em;
            }
        }

        @include md {
            margin-bottom: 0.5em;
        }
    }

    ul {
        display: flex;
        align-items: center;

        li {
            list-style-type: none;

            &.social-media-footer {
                display: flex;
                list-style-type: none;

                a img {
                    width: 20px;
                    height: 20px;
                }

                .facebook {
                    margin-right: 1em;
                }
            }
        }
    }
}

// -- Homepage

#home-hero-section {
    .home-hero-content {
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-image: url("/img/hero-bg.png");

        .home-hero-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 400px;
            text-align: left;

            @include xl {
                left: 70%;
                transform: translate(-70%, -50%);
            }

            @include md {
                position: static;
                transform: none;
                padding: 2em 40px;
                max-width: 100%;
            }

            h1 {
                font-size: 3.25rem;
                margin: 0;
                color: $white;

                @include md {
                    font-size: 2.75rem;
                }

                @include xs {
                    font-size: 2.5rem;
                }
            }

            h2 {
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 27px;
                margin: 1em 0;
                color: $white;

                @include xs {
                    font-size: 1.125rem;
                }
            }
        }
    }

    .home-hero-img {
        width: 100%;
        height: 100%;
        min-height: 650px;
        background-size: cover;
        background-position: center;

        @include md {
            min-height: 60vw;
            display: block;
        }
    }
}

#homepage-content {
    padding: 4em 0;
    text-align: left;

    @include md {
        padding: 3em 0;
    }

    #home-about-links {
        align-items: flex-start;

        @include md {
            flex-direction: column;
            align-items: center;
        }

        #home-about {
            width: 100%;
            max-width: 550px;
            margin-right: 2em;

            @include md {
                max-width: 100%;
                margin-right: 0;
            }

            h2 {
                font-size: 1.75rem;
                line-height: 37px;
                margin-bottom: 1em;

                @include md {
                    font-size: 1.5rem;
                    line-height: 30px;
                }
            }

            .button.primary {
                margin-top: 1em;
            }
        }

        #home-links {
            width: 500px;

            @include md {
                max-width: 100%;
                margin-top: 4em;
            }

            h2 {
                @include md {
                    text-align: center;
                }

                &:before {
                    content: "";
                    display: block;
                    width: 50px;
                    padding-bottom: 0.5em;
                    border-top: 3px solid $primary;

                    @include md {
                        margin: 0 auto;
                    }
                }
            }

            ul {
                margin-top: 1.5em;

                li {
                    display: flex;
                    justify-content: space-between;
                    padding: 1em 0;
                    font-size: 1.125rem;
                    font-weight: 600;
                    list-style-type: none;
                    border-bottom: 1px solid $dark-grey;

                    &:after {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 22px;
                        margin-right: 0.75em;
                        vertical-align: middle;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-image: url("/img/arrow-right.svg");
                    }

                    a {
                        color: inherit;
                        text-decoration: none;

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}

#homepage-icon {
    padding: 4em 0;
    background: #d9d9d9;

    @include md {
        padding: 3em 0;
    }

    .homepage-icon-text {
        max-width: 800px;
        margin: 0 auto;
        text-align: center;

        @include md {
            max-width: 600px;
        }

        h2 {
            font-size: 1.75rem;
            line-height: 37px;
            margin-bottom: 1em;

            @include md {
                font-size: 1.5rem;
                line-height: 30px;
            }
        }
    }

    .homepage-icon-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2em;
        margin-top: 4em;

        @include xs {
            grid-template-columns: repeat(1, 1fr);
            gap: 2em;
            margin-top: 2em;
        }

        .homepage-icon-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            max-width: 400px;

            @include xs {
                max-width: 300px;
                margin: 0 auto;
            }

            img {
                @include md {
                    width: 120px;
                }

                @include xs {
                    width: 100px;
                }
            }

            p {
                margin-top: 1em;
            }
        }
    }
}

// -- CTA

#home #cta {
    background-color: $white;
}

#cta {
    background-color: #d9d9d9;
    padding: 4em 0;

    @include md {
        padding: 2em 0;
    }

    .cta-text {
        max-width: 800px;
        margin: 0 auto;
        text-align: center;

        @include md {
            max-width: 600px;
        }

        h2 {
            font-size: 1.75rem;
            line-height: 37px;
            margin-bottom: 1em;

            @include md {
                font-size: 1.5rem;
                line-height: 30px;
            }
        }

        .button.primary {
            display: block;
            width: fit-content;
            margin: 0 auto;
        }
    }
}

// -- Contact Page

.contact-form-wrapper {
    padding: 2em;
    max-width: 800px;
    border-radius: 3px;
    background-color: #d9d9d9;

    @include lg {
        max-width: 100%;
        padding: 1.5em;
    }

    .button.primary {
        margin: 1em 0;
    }
}

// -- Venue Info page

// Venue index page

#venues-info-content {
    margin-bottom: 4em;
    .venues-grid {
        display: grid;
        gap: 2em;
        grid-template-columns: repeat(4, 1fr);

        @include md {
            grid-template-columns: repeat(2, 1fr);
        }

        @include xs {
            grid-template-columns: repeat(1, 1fr);
        }

        .venue-item {
            max-width: 100%;
            list-style-type: none;
            background-color: $white;
            border-radius: 0 0 5px 5px;
            box-shadow: 0 4px 12px rgb(17 17 26 / 3%),
                0 6px 16px rgb(17 17 26 / 5%);
            padding: 0 0 1rem;
            text-align: center;

            a {
                text-decoration: none;
                color: inherit;
            }

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
                border-radius: 5px 5px 0 0;
            }

            .venue-item-details {
                display: block;
                margin-top: 1em;

                h4 {
                    font-size: 1.125rem;
                    margin-bottom: 0.5em;
                }

                span {
                    display: block;
                    font-size: 1rem;
                    margin-top: 0.5em;
                    color: $primary;
                }
            }
        }
    }
}

// Venue individual page

.venue-address {
    p {
        &:before {
            content: "";
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 0.75em;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("/img/location-marker.svg");
        }
    }
}

#venue-page-content {
    margin-bottom: 4em;

    .container.row {
        @include md {
            flex-direction: column-reverse;
        }
    }
}

.venue-desc {
    display: block;
    text-align: left;
    margin-right: 3em;

    @include md {
        margin-top: 2em;
        margin-right: 0;
    }

    h1 {
        font-size: 2.5rem;

        @include xs {
            font-size: 2rem;
        }

        &:before {
            content: "";
            display: block;
            width: 50px;
            padding-bottom: 0.5em;
            border-top: 3px solid $primary;
        }
    }

    img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        object-position: center;
        margin: 2em 0 0;
    }
}

.venue-info {
    min-width: 500px;
    background-color: #d9d9d9;
    padding: 1.5em;
    text-align: left;

    @include md {
        min-width: 100%;
    }

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1em;

        &:before {
            content: "";
            display: block;
            width: 50px;
            padding-bottom: 0.5em;
            border-top: 3px solid $primary;
        }
    }

    .venue-info-link {
        &:before {
            content: "";
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 0.75em;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("/img/link.svg");
        }

        a {
            color: $black;
            display: inline-block;
            font-size: 1rem;
        }
    }
}

// -- Courses page

// Course index page

#courses-page-content {
    margin-bottom: 4em;

    .row {
        align-items: flex-start;
    }

    .courses-selection {
        width: calc(100% - 500px);
        padding-right: 2em;

        @include lg {
            width: calc(100% - 300px);
        }

        @include xs {
            width: 100%;
            padding-right: 0;
        }

        .courses-grid {
            display: grid;
            gap: 2em;
            grid-template-columns: repeat(2, 1fr);

            @include md {
                grid-template-columns: repeat(1, 1fr);
            }

            .courses-item {
                max-width: 100%;
                list-style-type: none;
                background-color: $white;
                border-radius: 0 0 5px 5px;
                box-shadow: 0 4px 12px rgb(17 17 26 / 3%),
                    0 6px 16px rgb(17 17 26 / 5%);
                padding: 0 0 1rem;
                text-align: center;

                a {
                    text-decoration: none;
                    color: inherit;
                }

                img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                    border-radius: 5px 5px 0 0;
                }

                .course-item-details {
                    display: block;
                    margin-top: 1em;
                    padding: 0.5em 2em;

                    h4 {
                        font-size: 1.125rem;
                        margin-bottom: 0.5em;
                    }

                    span {
                        display: block;
                        font-size: 1rem;
                        margin-top: 0.5em;
                        color: $primary;
                    }
                }
            }
        }
    }

    .courses-quick-links {
        width: 500px;
        padding-left: 2em;
        text-align: left;
        border-left: 1px solid #d9d9d9;

        @include lg {
            width: 300px;
        }

        @include xs {
            width: 100%;
            margin: 2em auto;
            padding-left: 0;
            border: none;
        }

        h2 {
            text-align: left;

            @include xs {
                text-align: center;
            }

            &:before {
                content: "";
                display: block;
                width: 50px;
                padding-bottom: 0.5em;
                border-top: 3px solid $primary;

                @include xs {
                    margin: 0 auto;
                }
            }
        }

        ul {
            margin-top: 1.5em;

            li {
                display: flex;
                justify-content: space-between;
                padding: 1em 0;
                font-size: 1.125rem;
                font-weight: 600;
                list-style-type: none;
                border-bottom: 1px solid $dark-grey;

                &:after {
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 22px;
                    margin-right: 0.75em;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-image: url("/img/arrow-right.svg");
                }

                a {
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
}

// Course individual page

.product.details {
    margin-top: 2em;
}

#view-cart-link {
    margin: 1.25em auto 0;
    display: none;
    text-align: center;
}

// -- CMS Page

#cms-page-banner {
    margin-bottom: 4em;

    .container.aes {
        position: relative;
    }

    .cms-page-banner-img {
        width: 100%;
        height: 145px;
        object-fit: cover;
    }

    .cms-page-banner-aes {
        position: absolute;
        bottom: -11em;
        right: 4em;
        max-width: 400px;

        @include lg {
            bottom: -5em;
            right: 4em;
            max-width: 250px;
        }

        @include xs {
            bottom: -2em;
            right: 4em;
            max-width: 200px;
        }
    }

    .cms-page-banner-intro {
        display: block;
        text-align: left;
        width: 800px;

        &.course {
            @include lg {
                width: 100%;
                margin-right: 3em;
            }

            @include xs {
                margin-right: 0;
            }

            img {
                width: 100%;
                max-height: 400px;
                object-fit: cover;
                margin: 2em 0 1em;
            }
        }

        @include lg {
            width: 100%;
        }

        h1 {
            font-size: 2.5rem;

            @include xs {
                font-size: 2rem;
            }

            &:before {
                content: "";
                display: block;
                width: 50px;
                padding-bottom: 0.5em;
                border-top: 3px solid $primary;
            }
        }

        h2 {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 1em;

            @include xs {
                font-size: 1.125rem;
            }
        }

        a {
            color: $primary;
            font-weight: 700;
            text-decoration: underline;
        }
    }
}

#cms-page-content {
    margin: 3em auto;
    text-align: left;

    @include md {
        margin: 2em auto;
    }

    .content-block {
        .text {
            h2 {
                margin: 1.5em auto 1em;
                font-size: 1.25rem;
                padding: 0.5em 1em;
                color: $white;
                background-color: $secondary;
            }

            a {
                color: $primary;
                font-weight: 700;
                text-decoration: underline;
            }

            img {
                @include md {
                    width: 100% !important;
                    margin: 0 0 1em !important;
                }
            }
        }

        .accordion {
            .accordion-intro {
                h2 {
                    margin: 1.5em auto 1em;
                    font-size: 1.25rem;
                    padding: 0.5em 1em;
                    color: $white;
                    background-color: $secondary;
                }
            }
        }
    }
}

// -- Course Page

.products.single {
    #cms-page-banner {
        @include md {
            margin-bottom: 2em;
        }
    }
}

#course-page-content {
    margin-bottom: 4em;

    .container.row {
        @include md {
            flex-direction: column;
        }
    }
}

.course-desc {
    display: block;
    text-align: left;
    margin-right: 3em;

    @include md {
        margin-bottom: 2em;
        margin-right: 0;
    }

    h1 {
        font-size: 2.5rem;

        @include xs {
            font-size: 2rem;
        }

        &:before {
            content: "";
            display: block;
            width: 50px;
            padding-bottom: 0.5em;
            border-top: 3px solid $primary;
        }
    }

    img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        object-position: center;
        margin: 1em 0;
    }
}

.course-info {
    min-width: 500px;
    background-color: #d9d9d9;
    padding: 1.5em;
    text-align: left;

    @include md {
        min-width: 100%;
    }

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1em;

        &:before {
            content: "";
            display: block;
            width: 50px;
            padding-bottom: 0.5em;
            border-top: 3px solid $primary;
        }
    }

    .course-info-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0.5em 0;
        border-bottom: 1px solid $secondary;

        @include xxs {
            flex-direction: column;
        }

        &.course {
            margin-top: 2em;
        }
    }

    p {
        font-size: 1rem;
    }

    .product.details {
        background-color: $secondary;
        padding: 1em;
        color: $white;
        text-align: center;

        .product-sold-out {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            @include xs {
                flex-direction: column;
            }

            h3 {
                font-size: 1rem;
            }
        }

        #add-to-cart {
            margin: 0;

            @include xs {
                margin-top: 1em;
            }

            #product-form {
                display: block;

                .button.primary {
                    margin: 0;
                    font-size: 0.85rem;
                    padding: 0.75rem 1.25rem;
                }
            }
        }
    }
}

// -- Auth Pages

.auth {
    max-width: 30rem;
    margin: 4rem auto;
    padding: 1rem;
    border-radius: 5px;
    background-color: #d9d9d9;

    h3 {
        font-size: 1.25rem;
        text-align: center;
        padding: 1em 0;
        color: $secondary;
        margin: 0;
    }
}

.login {
    @extend .auth;
    text-align: center;

    .button {
        border: none;
        background-color: $primary;
        color: $white;
        width: 100%;
    }

    .register-link {
        display: block;
        margin-top: 0.75em;
        color: $secondary;
        font-weight: 600;
    }

    .forgot-pwd-link {
        margin: 1.5em 0 1em;
        color: $secondary;
        display: block;
    }
}

.register {
    @extend .auth;

    .button {
        margin: 1em auto;
        border: none;
        background-color: $primary;
        color: $white;
        width: 100%;
    }

    .register-link {
        display: block;
        color: inherit;
        font-size: 1rem;
        margin-top: 0.5em;
        font-weight: 600;
    }
}

.forgotpass {
    @extend .auth;
}

.field-error {
    text-align: left;
    display: block;
    color: $negative;
    font-size: 0.85rem;
    padding-top: 0.25rem;
}

/* -- Account page -- */

.account-pages {
    #cms-page-banner {
        @include md {
            margin-bottom: 2em;
        }
    }
}

#account-details {
    margin-bottom: 4em;
}

.account {
    @extend .container;
    padding: 0;
    height: 100%;
    display: flex;
    gap: 1em;
    // display: grid;
    // grid-template-columns: 1fr 4fr;
    // grid-template-rows: 1fr;
    // gap: 1em 1em;
    // grid-template-areas: "account-sidebar account-body";

    @include md {
        display: block;
    }

    .account-sidebar {
        // grid-area: account-sidebar;
        width: 300px;
        background-color: #d9d9d9;
        padding: 1rem 1rem 30rem 1rem;

        @include md {
            width: 100%;
            padding: 1rem;
        }

        ul {
            list-style: none;
            text-align: left;

            li {
                width: 100%;
            }

            a {
                text-decoration: none;
            }
        }
    }

    .account-nav-item {
        margin: 1rem 0;
        font-size: 1.125rem;

        &.current {
            font-weight: 600;
        }
    }

    .account-body {
        // grid-area: account-body;
        width: calc(100% - 300px);
        padding: 1rem;
        text-align: left;

        @include md {
            width: 100%;
            padding: 2rem 0;
        }

        h2 {
            text-align: left;

            @include xs {
                margin: 1em 0;
            }

            &:before {
                content: "";
                display: block;
                width: 50px;
                padding-bottom: 0.5em;
                border-top: 3px solid $primary;
            }
        }

        header {
            background-color: #d9d9d9;
            padding: 1em;
            margin: 1em 0;

            h3 {
                font-size: 1rem;
            }
        }

        .buttons {
            display: flex;
            flex-wrap: wrap;
            margin: 1em 0;

            .button.secondary {
                margin: 0.5em 0.5em 0.5em 0;
            }
        }

        .account-page-content {
            width: 100%;
            margin-top: 1em;

            .row {
                @include md {
                    display: block;
                }
            }

            header.row {
                @include md {
                    margin-top: 1em;
                }
            }

            .order-history-table {
                td {
                    text-align: center;
                }
                .view-link {
                    color: $primary;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }

            .order-addresses .address {
                margin-top: 1em;
                padding: 0;
            }
        }

        .detail-order {
            padding-top: 1em;

            .detail-order-item {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $primary;
                padding-bottom: 0.25em;
                margin-bottom: 0.5em;
                font-size: 1rem;
                color: $primary;
            }
        }
    }

    .account-body-form {
        max-width: 22rem;
    }

    .table-container.overflow-scroll {
        display: block;
        max-width: fit-content;
        margin: 0 auto;
        overflow-x: auto;
        white-space: nowrap;
    }

    .order-details-table {
        width: inherit;
        th,
        td {
            width: 120px;
            vertical-align: middle !important;
            white-space: normal;
            padding: 0.625em;
            text-align: left;
            word-break: break-word;

            @include md {
                width: 100%;
            }
        }
    }
}

// Scrollbar Override
.scroll-inner {
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparentize($primary, 0.7);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparentize($primary, 0.5);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
}

// -- Pagination

.pagination {
    margin: 2rem auto;
    display: flex;
    width: max-content;
    justify-content: space-between;
    list-style: none;

    .hidden {
        display: none !important;
    }

    .page-item {
        height: 100%;
        width: 2em;
        text-align: center;
        border-radius: 3px;
        height: 2em;
        padding: 0.25em;
        background-color: #c4c4c4;
        color: $white;
        margin: 0 0.25em;

        a {
            color: inherit;
            text-decoration: none;
            vertical-align: middle;

            &:hover {
                text-decoration: none;
            }

            &.arrow {
                color: grey;
            }
        }

        &:first-of-type {
            border-left: 0;
        }

        &.active {
            background: $primary;
            color: white;

            span {
                line-height: 1.5em;
            }
        }

        &.arrow {
            background: transparent;
            color: grey;
        }

        &.disabled {
            background: transparent;
            color: grey;
        }
    }
}

/* -- Cart -- */

#category-header {
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    margin-bottom: 2em;
    text-align: left;
    background-color: $secondary;

    @include xs {
        margin-bottom: 1em;
    }

    .container {
        position: relative;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        h1 {
            color: $white;
            margin: 0 0 0.25em;
            font-size: 1.5rem;

            a {
                color: $white;
                text-decoration: none;
            }
        }

        p {
            color: $white;
            line-height: 20px;
        }
    }
}

main.cart,
#checkout-cart {
    section {
        max-width: 100%;
    }

    form {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    #checkout-cart {
        max-width: 100%;
        margin-bottom: 2em;
    }

    .cart-empty {
        margin: 2em auto;
        text-align: left;

        .button {
            margin-top: 1em;
        }
    }

    .cart-row {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1em 2em;
        margin-bottom: 2em;

        @include md {
            grid-template-columns: 1fr;
            margin-bottom: 2em;
        }
    }

    #cart-table {
        table-layout: auto;

        thead {
            display: table-header-group;

            p {
                font-size: 1rem;
            }
        }

        th {
            text-align: left;
            white-space: normal;
        }

        tr {
            position: relative;
            display: table-row;
            padding: 0.5em 0;
        }

        td {
            padding: 1em 0.5em;
            vertical-align: middle;

            .reference-form {
                margin-top: 1em;
                input {
                    padding: 0.5rem 1em;
                    border-radius: 3px;
                }
            }
        }
    }

    .au-dollar-prompt {
        text-align: right;
        font-size: 0.75rem;
        margin: 1em 0 0;

        @include xs {
            text-align: center;
        }
    }

    #cart-table-body {
        .product-image img {
            display: block;
            width: 100%;
            max-width: 100px;
            height: auto;
        }

        .item-remove {
            @include xs {
                position: absolute;
                top: 0;
                right: 1rem;
                z-index: 2;
            }
        }

        .item-qty {
            @include xs {
                flex-direction: row;
                align-items: center;
            }
        }

        .sub-qty,
        .add-qty {
            img {
                width: 0.75rem;
                height: 0.75rem;
                object-position: center;
                vertical-align: middle;
                margin: 0 0.75rem;
            }
        }

        .product-remove {
            all: unset;
            cursor: pointer;
            background-color: $primary;
            mask-image: url("/img/icon-remove.svg");
            mask-repeat: no-repeat;
            mask-position: center center;
            mask-size: contain;
            width: 12px;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }

    .cart-summary {
        padding: 2em;
        background-color: #d9d9d9;
        height: fit-content;

        .section-title {
            font-size: 1.5rem;
            margin-bottom: 1em;
        }

        .totals {
            .total {
                display: flex;
                justify-content: space-between;
                width: 100%;

                &.order {
                    padding-top: 1em;
                    border-top: 1px solid $primary;
                    margin-top: 1em;
                    color: $primary;
                    font-size: 1.25rem;
                    font-weight: 600;
                }

                &.remaining {
                    padding-top: 1em;
                    border-top: 1px solid $primary;
                    margin-top: 1em;
                    color: $secondary;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
            }
        }

        .buttons {
            margin-top: 1em;
            .button {
                text-align: center;
                margin: 0.5em auto;
                width: 100%;
            }
        }
    }

    .cart-coupon {
        padding-top: 1em;
        margin-top: 1em;
        border-top: 1px solid $dark-grey;

        h3 {
            margin-bottom: 1em;
        }

        .button.primary {
            margin-top: 0;
            padding: 1em;
            width: fit-content;
            margin-left: 0.5em;
        }
    }
}

.checkout-address {
    .cms-section-title {
        margin: 0;
    }
}

// Add to cart form
#add-to-cart {
    max-width: 100%;
    margin: 0 auto;

    #product-form {
        button {
            width: 100%;
            margin-top: 1em;
        }
    }
}

.quick_search_result {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1em;
    border-bottom: 1px solid $primary;
    background-color: $white;
    padding: 0.5em 1em;
    align-items: center;
    text-align: left;
    font-size: 0.85rem;
    line-height: 20px;

    .quick-product-img {
        img {
            display: block;
            width: 100%;
            max-width: 80px;
            height: 80px;
        }

        @include xs {
            width: 40%;
        }
    }

    .quick-product-details {
        max-width: 200px;

        @include xs {
            width: 60%;
            max-width: 60%;
        }
    }

    .quick-product-total {
        width: 100px;
    }

    .quick-product-qty {
        @include xs {
            width: 50%;
            margin: 0.5em 0;
        }
        label {
            padding: 0;
            margin-top: -20px;
            font-size: smaller;

            @include xs {
                margin-top: 0;
            }
        }
        input {
            padding: 0.5em;
        }
    }

    .button {
        @include xs {
            width: 100%;
        }
        margin: 0;
        padding: 0.5em;
    }
}

// -- Checkout Pages
main.checkout section {
    max-width: 100%;
    margin: 0;
}

#checkout-payment-page,
#checkout-complete-page,
#checkout-cancelled-page {
    margin: 2em 0 4em;
}

// Address

#billing-address {
    display: block;
}

.shipping-details {
    .cms-section-title {
        margin-top: 0;
    }
}

.buttons.next {
    .button.primary {
        float: right;
        margin-top: 1em;
    }
}

.form-section-title {
    display: block;
    text-align: left;
    background-color: #d9d9d9;
    font-size: 1.125rem;
    margin: 1rem 0;
    padding: 0.65rem 1em;
}

.form-section {
    .row {
        .column {
            @include xs {
                width: 100%;
            }

            fieldset {
                @include xs {
                    width: 100%;
                }
            }
        }
    }
}

// Payment
.checkout-payment {
    .cms-section-title {
        margin-top: 0;
    }
}

.payment-wrapper {
    padding: 0.65rem 1em;

    @include xs {
        padding: 0.65rem 0;
    }

    .row.form {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;

        .left,
        .right {
            width: 50%;

            @include xs {
                width: 100%;
            }
        }

        .right {
            margin-left: 1em;

            @include xs {
                margin-left: 0;
            }
        }
    }

    .payment-method {
        padding: 1em 2em 3em;
        background-color: #d9d9d9;
        margin-top: 1em;

        @include xs {
            padding: 1em 1em 3em;
        }

        .message {
            text-align: left;
            p {
                font-size: 1rem;
            }
        }
    }

    .button.primary {
        float: right;
        margin: 1em 0 2em;
    }
}

// Order Confirmation

.order-confirmation {
    text-align: center;
    background-color: #d9d9d9;
    padding: 2em 20px;

    .order-confirmation-img-status {
        width: 60px;
        margin: 0 auto;
        display: block;
    }

    .link {
        color: $primary;
    }

    .button {
        margin: 1em auto;
    }
}

.order-summary {
    padding: 2em;
    background-color: #d9d9d9;

    .order-items {
        margin-top: 1em;

        .cart-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid $secondary;
            padding-top: 1em;
            margin-bottom: 1em;

            .item-details {
                display: flex;
                align-items: center;

                img {
                    width: 4em;
                    height: 4em;
                    object-fit: cover;
                    margin-right: 1em;
                }
            }
        }
    }

    .order-totals {
        border-top: 2px solid $secondary;
        padding-top: 1em;

        .total {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            &.order {
                border-top: 1px solid $secondary;
                padding-top: 0.75em;
                margin-top: 0.75em;
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }
}

.order-addresses {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1em;
    margin-top: 1em;

    @include xs {
        grid-template-columns: repeat(1, 1fr);
        gap: 1em;
    }

    h3 {
        border-bottom: 1px solid $secondary;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
    }

    .address {
        background-color: #d9d9d9;
        text-align: left;
        padding: 2em;
    }
}

/* -- Stripe -- */

.stripe-field {
    background: #d2000000;
    font-weight: 300;
    border: 0;
    color: $secondary;
    outline: none;
    cursor: text;
    display: block;
    width: 100%;
    line-height: 32px;
    padding-bottom: 3px;
    transition: opacity 200ms ease-in-out;
}

.StripeElement {
    background-color: white;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
