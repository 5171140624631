@import "_breakpoints.scss";

.content-block {
  &.cols-2 {
    display: flex;

    @include md{
      flex-direction: column;
    }
    
    section {
      flex-basis: 50%;
      max-width: 50%;
      padding: 0 2em 0 0;
      img {
        max-height: 400px;
      }
    }
  }
  &.cols-3 {
    display: flex;
    section {
      flex-basis: 33.3333333%;
      max-width: 33.3333333%;
      padding: 0 2em 0 0;
      img {
        max-height: 400px;
      }
    }
  }
}
