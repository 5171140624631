// Accordion
.toggle {

    background-color: $light-grey;
    border: 1px solid $secondary;
    padding: 0.15rem 0.5rem;
    width: 100%;
    margin-bottom: 1em;
    border-radius: 2px;

    // &:nth-child(even) {
    //     .title-name {
    //         background: $light-grey;
    //     }
    // }

    // &:nth-child(odd) {
    //     .title-name {
    //         background: $white;
    //     }
    // }

    .toggle-title {
        position: relative;
        display: block;

        h3 {
            margin: 0px;
            font-weight: 700;
            cursor: pointer;
        }
        &.active h3 {}
    }

    .toggle-inner {
        padding: 0 1em 1em 1em;
        display: none;
        div {
            max-width: 100%;
        }

        p{
            font-size: 1rem;
        }
    }

    .toggle-title {
        .title-name {
            display: block;
            padding: 1em 3em 1em 1em;

            &:after {
                position: absolute;
                content: '\002B';
                width: 24px;
                height: 24px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
                right: 1em;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                font-size: 1.75rem;
                line-height: 20px;
                color: $primary;
            }
        }

        &.active .title-name:after {
            content: '\2212';
        }
    }
}